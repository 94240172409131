<template>
  <div class="cont">
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>业务中台</el-breadcrumb-item>
           <el-breadcrumb-item>限时秒杀</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <!-- tabs标签页 -->
     <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="秒杀预售券" name="first">
           <!-- 查询框 -->
           <el-row class="search-box">
              <el-row>
                 <label>预售券名称：</label>
                 <el-input class="width-175 m-left-5"  v-model="param.mainTitle"></el-input>
              </el-row>
              <el-row>
                 <label>秒杀时段：</label>
                 <el-select v-model="param.seckillTimeId"  class="width-175" :placeholder="$t('msg.select')">
                    <el-option
                        v-for="item in seckillTime"
                        :key="item.id"
                        :label="item.timeRange"
                        :value="item.id">
                    </el-option>
                 </el-select>
              </el-row>
              <el-row>
                 <el-button  class="bg-gradient" type="primary" @click="searchCoupon(true)">搜索</el-button>
                 <el-button  @click="searchCoupon(false)">重置</el-button>
              </el-row>
           </el-row>
           <!-- 表格 -->
           <el-row class="table-box">
              <el-button class="add-btn bg-gradient" type="primary" @click="addSeckillProduct">新增秒杀预售券</el-button>
              <el-table
                  ref="mainTable"
                  border
                  :data="couponData"
                  :stripe="true"
                  >
                 <el-table-column label="序号" width="60">
                    <template slot-scope="scope">
                       {{scope.$index + 1}}
                    </template>
                 </el-table-column>
                 <el-table-column prop="mainTitle" label="预售券名称" min-width="150"></el-table-column>
                 <el-table-column prop="productName" label="预售产品名称" min-width="140"></el-table-column>
                 <el-table-column prop="quantity" label="预售产品数量"></el-table-column>
                 <el-table-column prop="presellPrice" label="预售券价格"></el-table-column>
                 <el-table-column prop="seckillPrice" label="秒杀价格"></el-table-column>
                 <el-table-column prop="salesVolume" label="销量" min-width="40"></el-table-column>
                 <el-table-column label="秒杀时段" min-width="160">
                    <template slot-scope="scope">
                       <el-row v-if="scope.row.dateType === 'EVERYDAY'">
                          每天
                          <p>{{ scope.row.startTime }}至{{ scope.row.endTime }}</p>
                       </el-row>
                       <el-row v-else-if="scope.row.dateType === 'WEEK'">
                          <p>
                             <span v-if="scope.row.mon === 'YES'">周一</span>,
                             <span v-if="scope.row.tue === 'YES'">周二</span>,
                             <span v-if="scope.row.wed === 'YES'">周三</span>,
                             <span v-if="scope.row.thu === 'YES'">周四</span>,
                             <span v-if="scope.row.fri === 'YES'">周五</span>,
                             <span v-if="scope.row.sat === 'YES'">周六</span>,
                             <span v-if="scope.row.sun === 'YES'">周日</span>
                          </p>
                          <p>{{ scope.row.startTime }}至{{ scope.row.endTime }}</p>
                       </el-row>
                       <el-row v-else-if="scope.row.dateType === 'DATE'">
                          <p>{{ scope.row.startDate }}至{{ scope.row.endDate }}</p>
                          <p>{{ scope.row.startTime }}至{{ scope.row.endTime }}</p>
                       </el-row>
                    </template>
                 </el-table-column>
                 <el-table-column prop="status" label="状态">
                    <template slot-scope="scope">
                       {{ scope.row.status | filterStatus(that) }}
                    </template>
                 </el-table-column>
                 <el-table-column :label="$t('msg.operating')" fixed="right" min-width="130">
                    <template slot-scope="scope">
                       <el-button type="text" icon="el-icon-edit" @click="editSeckillProduct(scope.row)" >
                          <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                       </el-button>
                       <el-button type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="delSeckillProduct(scope.row.id)" >
                          <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                       </el-button>
                       <el-button type="text" v-show="scope.row.status === 'OFFSHELF'" style="color: #F56C6C;" icon="el-icon-delete" @click="changeStatus(scope.row.id, 'ONSHELF')" >
                          <el-link type="danger">上架</el-link>
                       </el-button>
                       <el-button type="text" v-show="scope.row.status === 'ONSHELF'" style="color: #F56C6C;" icon="el-icon-delete" @click="changeStatus(scope.row.id, 'OFFSHELF')" >
                          <el-link type="danger">下架</el-link>
                       </el-button>
                    </template>
                 </el-table-column>
              </el-table>
              <!-- 分页 -->
              <pagination :total="couponTotal" :page-size="param.limit" @handleSizeChangeSub="couponPageNum" @handleCurrentChangeSub="couponCurrPage"/>
           </el-row>
        </el-tab-pane>
        <!-- <el-tab-pane label="秒杀商品" name="second"></el-tab-pane>-->
        <el-tab-pane label="秒杀时间段设置" name="third">
           <!-- 表格 -->
           <el-row class="table-box">
              <el-button class="add-btn bg-gradient"  type="primary" @click="addSeckillTime">新增秒杀时间段</el-button>
              <el-table
                  ref="mainTable"
                  border
                  :data="seckillTime"
                  :stripe="true"
                  >
                 <el-table-column label="序号" width="60">
                    <template slot-scope="scope">
                       {{scope.$index + 1}}
                    </template>
                 </el-table-column>
                 <el-table-column label="秒杀时段" prop="timeRange"></el-table-column>
                 <el-table-column label="开始时间" prop="startTime"></el-table-column>
                 <el-table-column label="结束时间" prop="endTime"></el-table-column>
                 <el-table-column :label="$t('msg.operating')" fixed="right" min-width="110">
                    <template slot-scope="scope">
                       <el-button type="text" icon="el-icon-edit" @click="editSeckillTime(scope.row)" >
                          <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                       </el-button>
                       <el-button type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="delSeckillTime(scope.row.id)" >
                          <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                       </el-button>
                    </template>
                 </el-table-column>
              </el-table>
              <!-- 分页 -->
              <pagination :total="timeTotal" :page-size="timeLimit" @handleSizeChangeSub="timePageNum" @handleCurrentChangeSub="timeCurrPage"/>
           </el-row>
        </el-tab-pane>
     </el-tabs>
     <!-- 新增秒杀时间段 -->
     <el-dialog title="新增秒杀时间段" :visible.sync="visible" width="550px">
        <el-form class="form-dialog-box" :model="ruleForm" :rules="rules" ref="ruleForm">
           <el-form-item label="开始时间" prop="startTime">
              <el-time-picker
                  v-model="ruleForm.startTime"
                  class="width-300"
                  value-format="HH:mm:ss"
                  placeholder="任意时间点">
              </el-time-picker>
           </el-form-item>
           <el-form-item label="结束时间" prop="endTime">
              <el-time-picker
                  v-model="ruleForm.endTime"
                  class="width-300"
                  value-format="HH:mm:ss"
                  placeholder="任意时间点">
              </el-time-picker>
           </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button  @click="visible = false" v-text="$t('msg.reset')">取消</el-button>
            <el-button class="bg-gradient" type="primary" @click="saveSeckillTime()" v-text="$t('msg.save')">保存</el-button>
         </span>
     </el-dialog>
  </div>
</template>

<script>
import { urlObj } from '@/api/interface'
import { operate } from  '@/api/interface/smtech'
import { mapState } from 'vuex'
import { getDict } from "@/common/js/common";
export default {
  data(){
     return{
        activeName: 'first',   // tab栏
        that: this,
        /* 预售券 */
        couponData: [],        // 预售券列表
        couponTotal: 0,        // 预售券总数
        param: {
           mainTitle: '',
           seckillTimeId: '',
           limit: 1,
           page: 1,
        },
        /* 时间段 */
        ruleForm: {
           startTime: '',         // 秒杀开始时间
           endTime: '',           // 秒杀结束时间
        },
        rules: {
           startTime: [{ required: true, message: '请输入开始时间', trigger: 'blur' }],
           endTime: [{ required: true, message: '请输入结束时间', trigger: 'blur' }],
        },
        seckillTime: [],       // 秒杀时间段列表
        timeLimit: 1,          // 每页数
        timePage: 1,           // 当前页
        timeTotal: 0,          // 总页数
        visible: false,        // 显示弹窗

        action: 'add',         // 操作类型
        id: '',                // 当前秒杀时段ID
        /* 表单提交字段 */
        add_success: "添加成功！",
        del_success: "删除成功！",
        update_success: "修改成功！",
        confirm: '确定',
        cancel: '取消',
        confirm_remove: '确定移除？',
        prompt: '提示！',
        release_success: '发布成功！',
        confirm_up: '确定上架？',
        confirm_down: '确定下架？',
        up_success: '上架成功！',
        down_success: '下架成功！'
     }
  },
  computed: {
     ...mapState(['hotelInfo', 'dictData'])
  },
  mounted() {
     this.timeLimit = this.param.limit = sessionStorage.getItem('pageSize') * 1
     getDict(['goods-status'])
     this.getSeckillCoupons()
     this.getSeckillTime()
  },
  methods: {
     // 获取秒杀预售券列表
     getSeckillCoupons(){
        const url = operate.seckillCouponPage
        const param = { ...this.param, hotelId: this.hotelInfo.id }
        this.$axios.post(url, param).then(res => {
           if (res.success) {
              this.couponTotal = res.total
              this.couponData = res.records
              this.couponData.forEach(item => item.presellPrice && (item.presellPrice = Math.round(item.presellPrice / 100)))
           }
        })
     },
     // 搜索重置预售券
     searchCoupon(bool){
        this.param.page = 1
        if (bool) return this.getSeckillCoupons()
        this.param.seckillTimeId = ''
        this.param.mainTitle = ''
     },
     // 改变每页数
     couponPageNum(num){
        this.param.limit = num
        this.getSeckillCoupons()
     },
     // 改变当前页
     couponCurrPage(num){
        this.param.page = num
        this.getSeckillCoupons()
     },
     // 新增秒杀预售券/商品
     addSeckillProduct(){
        this.$router.push({ path: '/add_seckill', query: { action: 'add' } })
     },
     // 编辑秒杀预售券/商品
     editSeckillProduct(row){
        sessionStorage.setItem('seckillProductObj', JSON.stringify(row))
        this.$router.push({ path: '/add_seckill', query: { action: 'edit' } })
     },
     // 删除秒杀预售券/商品
     delSeckillProduct(id){
        this.$confirm(this.confirm_remove, this.prompt, {
           confirmButtonText: this.confirm,
           cancelButtonText: this.cancel,
           type: 'warning'
        }).then(() => {
           let ids = []; ids.push(id)
           const param = { ids: ids.toString() }
           const url = operate.delSeckillProduct
           this.$axios.post(url,param).then(res => {
              if (res.success){
                 this.$message({
                    showClose: true,
                    message: this.del_success,
                    type: 'success'
                 })
                 this.getSeckillCoupons()
              }
           })
        })
     },
     // 上下架商品
     changeStatus(id, status){
        this.$confirm(status === 'ONSHELF' ? this.confirm_up : this.confirm_down,this.prompt,{
           confirmButtonText: this.confirm,
           cancelButtonText: this.cancel,
           type: 'warning'
        }).then(() => {
           let param = { id, status }
           let url = operate.editSeckillProduct
           this.$axios.post(url, param).then(() => {
              this.$message({
                 showClose: true,
                 message: status === 'ONSHELF' ? this.up_success : this.down_success,
                 type: 'success'
              })
              this.getSeckillCoupons()
           })
        })
     },
     // 获取秒杀时间段列表
     getSeckillTime(){
        const url = operate.seckillTimePage
        const param = {
           limit: this.timeLimit,
           page: this.timePage,
           hotelId: this.hotelInfo.id
        }
        this.$axios.post(url, param).then(res => {
           if (res.success) {
              this.timeTotal = res.total
              this.seckillTime = res.records
              this.seckillTime.forEach(item => item.timeRange = item.startTime + '~' + item.endTime)
           }
        })
     },
     // 改变每页数
     timePageNum(num){
        this.timeLimit = num
        this.getSeckillTime()
     },
     // 改变当前页
     timeCurrPage(num){
        this.timePage = num
        this.getSeckillTime()
     },
     // 新增秒杀时间段
     addSeckillTime(){
        this.visible = true
        this.action = 'add'
     },
     // 编辑秒杀时间段
     editSeckillTime(row){
        this.visible = true
        this.action = 'edit'
        this.id = row.id
        for (let k in this.ruleForm) this.ruleForm[k] = row[k]
     },
     // 保存秒杀时间段
     saveSeckillTime(){
        let url = operate.addSeckillTime
        let param = { ...this.ruleForm, hotelId: this.hotelInfo.id }
        if (this.action === 'edit') {
           url = operate.editSeckillTime
           param.id = this.id
           delete param.hotelId
        }
        this.$axios.post(url, param).then(res => {
           if (res.success) {
              this.visible = false
              this.getSeckillTime()
              this.$message({
                 showClose: true,
                 message: this.action ? this.add_success : this.update_success,
                 type: 'success'
              })
           }
        })
     },
     // 删除秒杀时间
     delSeckillTime(id){
        this.$confirm(this.confirm_remove, this.prompt, {
           confirmButtonText: this.confirm,
           cancelButtonText: this.cancel,
           type: 'warning'
        }).then(() => {
           let ids = []; ids.unshift(id)
           const param = { ids: ids.toString() }
           const url = operate.delSeckillTime
           this.$axios.post(url,param).then(res => {
              if (res.success){
                 this.$message({
                    showClose: true,
                    message: this.del_success,
                    type: 'success'
                 })
                 this.getSeckillTime()
              }
           })
        })
     },
  },
  watch: {
     hotelInfo(newVal, oldVal) {
        if (newVal.id !== oldVal.id && oldVal) {
           this.getSeckillCoupons()
           this.getSeckillTime()
        }
     }
  },
  filters: {
     filterStatus(val, that){
        if (val && that.dictData['goods-status']) return that.dictData['goods-status'][val] || val
     }
  }
}
</script>

<style lang="scss" scoped>
.cont{
   .search-box{
      display: flex; padding: 10px 0 20px 0;
      .el-row{ margin: 0 10px 0 0 }
   }
   .table-box{
      .add-btn { margin-bottom: 10px; float: right }
      .member-info{
         display: flex;
         img { width: 60px; margin-right: 10px }
      }
   }
   .des{ color: red; margin-right: 5px }
}
</style>
